import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    domain: "https://api.yhbcc.com/", // http://mytest.com/  https://api.midbest.cn/
    dakaurl: ""
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
