<template>
  <div class="home" :style="'min-height:' + screeHeight + 'px;'">
    <div class="header">
      <van-cell :border="false" center>
        <template #icon>
          <van-image
            width="60"
            height="60"
            lazy-load
            :src="info.avatar"
            fit="cover"
            round
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
        </template>
        <template #title>
          <div class="shop_name">
            <div class="van-ellipsis">{{info.nickname}}</div><van-icon name="arrow" />
          </div>
          <div class="shop_data" @click="copy(info.agent_code)">
            邀请码：{{info.agent_code}} 
            <van-tag plain color="#F8FFFF">复制</van-tag>
          </div>
        </template>
      </van-cell>
    </div>
    <div class="order_data_main">
      <div id="test_app">
        <div id="main" style="width: 100%;height: 400px;background:#fff"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { ImagePreview } from 'vant';
import * as echarts from 'echarts';
export default {
  name: 'Home',
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      info: {}, // 用户信息
      page: '',
      charts: '',
      dayData: [],
			opinionData: []
    }
  },
  mounted() {
    this.page = this.$route.query.page ? this.$route.query.page : '';
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      if(this.$route.query.logincode){
        this.autoLoing(this.$route.query.logincode);
      }else{
        this.getinfo();
      }
    },

    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "team/user/get_info")
        .then(res => {
          if (res.data.code == 100000) {
            that.info = res.data.data;
            that.dayData = res.data.daydata;
            that.opinionData = res.data.pricedata;
            this.drawLine('main');
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    autoLoing(logincode) {
      let that = null;
      that = this;
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "team/user/auto_login",
          that.$qs.stringify({
            logincode: logincode
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            localStorage.setItem("SHOP_UID", res.data.data);
            localStorage.setItem("SHOP_LOGIN", true);
            if(that.page && that.page.length > 1) {
              that.$router.replace({
                path: '/'+that.page,
                query: {
                  t: new Date().getTime()
                }
              })
              return false;
            }
            that.$router.replace({
              name: "Home",
              query: {
                t: new Date().getTime()
              }
            });
            that.init();
          }else {
            that.$router.replace({
              name: "Login",
              query: {
                t: new Date().getTime()
              }
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 复制
    copy(text) {
      let _that = null;
      _that = this;
      _that.$copyText(text)
      .then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    drawLine(id) {
      this.charts = echarts.init(document.getElementById(id))
      this.charts.setOption({
        title:{
            left:'3%',
            top:'5%',
            text:"最近一周成交价",//标题文本，支持使用 \n 换行。
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top:'20%',
          left: '5%',
          right: '5%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap:true,
          axisTick:{
              alignWithLabel:true //保证刻度线和标签对齐
          },
          data: this.dayData //x坐标的名称
        },
        yAxis: {
          type: 'value',
          boundaryGap: true,
          
        },

        series: [{
          name: '成交',
          type: 'line', //折线图line;柱形图bar;饼图pie
          stack: '总量',
          areaStyle: {
            //显示区域颜色---渐变效果
            color:{
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, color: 'rgb(255,200,213)' // 0% 处的颜色
                }, {
                    offset: 1, color: '#ffffff' // 100% 处的颜色
                }],
                global: false // 缺省为 false
            }
          },
          itemStyle: {
            color: 'rgb(255,96,64)', //改变折线点的颜色
            lineStyle: {
              color: 'rgb(255,96,64)' //改变折线颜色
            } 
          },
          data: this.opinionData
        }]
			})
    }

  },
}
</script>
<style lang="less">
.home {
  background-color: #ECECEC;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  // 头部
  .header {
    padding: 20px 0 5px;
    background-color: #FF4F3C;
    overflow: hidden;
    .van-cell {
      color: #fff;
      background-color: transparent;
      .van-image {
        overflow: hidden;
        margin-right: 10px;
        border: 1px solid #fff;
      }
      .van-cell__title {
        flex: 1;
        text-align: left;
        overflow: hidden;
        .shop_name {
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }
        .shop_data {
          margin-top: 4px;
          font-size: 12px;
          color: #F8FFFF;
          display: flex;
          align-items: center;
          overflow: hidden;
          .van-tag {
            background-color: transparent;
            margin-left: 5px;
          }
        }
        .van-notice-bar {
          height: 24px;
          border-radius: 6px;
          font-size: 12px;
        }
      }
      .right_list {
        overflow: hidden;
        .van-grid-item__content {
          background-color: transparent;
          color: #fff;
          padding: 0;
          .van-grid-item__text {
            color: #fff;
            margin-top: 0;
          }
        }
      }
    }
  }
  .order_data_main {
    // 订单项
    .order_list {
      margin: 10px;
      padding: 10px 0 0;
      overflow: hidden;
      background-color: #fff;
      border-radius: 5px;
      .van-cell {
        .van-cell__title,
        .van-cell__default {
          flex: 1;
        }
        .van-cell__title { 
          text-align: left;
          font-weight: bold;
        }
      }
      .van-grid {
        .van-grid-item__icon-wrapper {
          font-size: 20px;
          font-weight: 450;
        }
        .van-grid-item__text {
          margin-top: 8px;
        }
      }
    }
    // 今日数据
    .shop_day_data {
      background-color: #fff;
      border-radius: 5px;
      margin: 10px;
      padding: 10px 10px 0;
      overflow: hidden;
      .van-cell {
        padding: 10px 0;
        .van-cell__title {
          text-align: left;
          font-weight: bold;
          margin-right: 10px;
          font-size: 16px;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
        }
      }
      .van-grid {
        .van-grid-item {
          .van-grid-item__content {
            padding: 10px 8px 16px 0;
          }
          &:last-of-type {
            .van-grid-item__content {
              padding: 10px 0;
            }
          }
        }
        .grid_item_title,
        .grid_item_num {
          margin-bottom: 12px;
          line-height: 24px;
          font-size: 14px;
          color: #333;
        }
        .grid_item_num {
          font-weight: 450;
          font-size: 14px;
          font-weight: bold;
        }
        .grid_item_member_title,
        .grid_item_member_num {
          line-height: 24px;
          font-size: 12px;
          color: #333;
        }
        .grid_item_member_num {
          font-weight: 450;
        }
        .grid_item_text {
          font-size: 12px;
          color: #999;
        }
        .hei {
          color: #333;
        }
        .mb0 {
          margin-bottom: 0;
        }
      }
    }
  }

  // 按钮列表
  .shop_btn_list {
    padding: 10px;
    margin: 10px;
    background-color: #fff;
    border-radius: 5px;
    .van-cell {
      padding: 10px 0;
    }
    .van-grid {
      .van-grid-item:nth-of-type(-n+4) {
        color: #EB5E40;
      }
      .van-grid-item:nth-of-type(n+4)~:nth-of-type(-n+8) {
        color: #379FF8;
      }
      .van-grid-item:nth-of-type(n+8)~:nth-of-type(-n+12) {
        color: #44B659;
      }
      .van-grid-item:nth-of-type(n+12)~:nth-of-type(-n+16) {
        color: orange;
      }
    }
  }
  // 加载中弹框
  .loading {
    width: 240px;
    padding: 20px;
  }
  .hei {
    color: #333;
  }
  .van-field__value {
    flex: 1;
  }
  .switch_btn_list{
    .van-cell{
        padding: 0px 4px;
    }
  }
}
</style>
