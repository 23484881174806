<template>
  <div id="app" ref="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <div id="nav">
      <van-tabbar v-model="active" v-if="$route.meta.TabbarShow" :safe-area-inset-bottom="true" z-index="10" placeholder >
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Home' }">
            <van-icon name="wap-home-o" />首页
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Zhuan', query: { xcx: this.xcx } }"> 
            <van-icon
              name="apps-o"
            />转让大厅
          </router-link>
        </van-tabbar-item>
        <van-tabbar-item>
          <router-link replace :to="{ name: 'Myhome', query: { xcx: this.xcx } }">
            <van-icon name="contact" />我的
          </router-link>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      shareid: 0,
      merchid: 0,
      active: 0,
      uid: 0,
      msgNum: 0,
      islogin: false,
      unreadmsgNum: 0,
      userinfo: {},
      xcx: 0
    }
  },
  mounted() {
    this.xcx = this.$route.query.xcx ? this.$route.query.xcx : 0;
  },
  methods: {

  },
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  height: 100%;
}
.van-cell__title,
.van-cell__value {
  -webkit-box-flex: none;
  -webkit-flex: none;
  flex: none;
}
.van-nav-bar {
  height: 50px;
  line-height: 50px;
}
.van-nav-bar .van-icon,
.van-nav-bar__text {
  color: #333;
}
#nav {
  a {
    display: block;
    font-weight: bold;
    color: #333;
    font-size: 12px;
    font-weight: normal;

    &.router-link-exact-active {
      color: #ee0a24;
    }
  }
  .van-tabbar--fixed {
    right: 0;
  }
  .van-tabbar-item__icon {
    display: none;
  }
  .van-tabbar-item__text {
    width: 100%;
    padding: 5px 0;
  }
  .van-tabbar-item__text .van-icon {
    display: block;
    margin-bottom: 5px;
    font-size: 20px;
  }
  .van-info {
    right: 20%;
  }
  
}
</style>
