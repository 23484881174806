import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home', // 首页
    component: Home,
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '首页'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'), // 市场页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '市场'
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/Order.vue'), // 消息页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '订单'
    }
  },
  {
    path: '/myhome',
    name: 'Myhome',
    component: () => import('../views/Myhome.vue'), // 我的页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '我的'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'), // 登陆页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: false, // 是否必须登陆
      pagetitle: '登录'
    }
  },
  {
    path: '/setpwd',
    name: 'Setpwd',
    component: () => import('../views/Setpwd.vue'), // 找回密码页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: false, // 是否必须登陆
      pagetitle: '找回密码'
    }
  },
  {
    path: '/zhuan',
    name: 'Zhuan',
    component: () => import('../views/Zhuan.vue'), // 转让大厅页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '转让大厅',
    }
  },
  {
    path: '/zhuanform',
    name: 'Zhuanform',
    component: () => import('../views/Zhuanform.vue'), // 发布转让页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '发布转让',
    }
  },
  {
    path: '/teaminfo',
    name: 'Teaminfo',
    component: () => import('../views/Teaminfo.vue'), // 商户信息
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '店铺信息'
    }
  },
  {
    path: '/zhuanmy',
    name: 'Zhuanmy',
    component: () => import('../views/Zhuanmy.vue'), // 我的转让页面
    meta: {
      keepAlive: true, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '我的转让',
    }
  },
  {
    path: '/accountlog',
    name: 'Accountlog',
    component: () => import('../views/Accountlog.vue'), // 明细页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '变动记录',
    }
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: () => import('../views/Withdraw.vue'), // 提现页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '申请提现',
    }
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import('../views/Wallet.vue'), // 钱包页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '我的联惠金',
    }
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('../views/Recharge.vue'), // 充值页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '充值',
    }
  },
  {
    path: '/rechargehisotry',
    name: 'RechargeHistory',
    component: () => import('../views/RechargeHistory.vue'), // 充值记录页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '充值记录',
    }
  },
  {
    path: '/paysuccess',
    name: 'PaySuccess',
    component: () => import('../views/PaySuccess.vue'), // 支付页面
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: true, // 是否显示底部导航
      requireLogin: true, // 是否必须登陆
      pagetitle: '支付成功',
    }
  },
  {
    path: '/qrcodeshou',
    name: 'Qrcodeshou',
    component: () => import('../views/Qrcodeshou.vue'), // 收款二维码
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: false, // 是否必须登陆
      pagetitle: '付款给商家'
    }
  },
  {
    path: '/qrcodefan',
    name: 'Qrcodefan',
    component: () => import('../views/Qrcodefan.vue'), // 返现收款码
    meta: {
      keepAlive: false, // 是否缓存页面
      TabbarShow: false, // 是否显示底部导航
      requireLogin: false, // 是否必须登陆
      pagetitle: '付款给商家'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition  //游览器返回记住位置
    } else {
      return { x: 0, y: 0 } //滚动到顶
    }
  }
})

export default router
